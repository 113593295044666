import { MutateAction } from '@komo-tech/core/models/Updates';
import { createContext } from 'react';

import { ToastFunction } from './ToastFunction';
import { ToastMessage } from './ToastMessage';
import { ToastSendOptions } from './ToastSendOptions';

/**
 * When ToasterContext is used outside of context provider, log an error.
 */

export const ToasterContext = createContext<ToasterContextState>(null);
ToasterContext.displayName = 'ToasterContext';

export interface ToasterContextState {
  message: ToastFunction;
  info: ToastFunction;
  error: ToastFunction;
  warning: ToastFunction;
  success: ToastFunction;
  pill: ToastFunction;
  pushNotification: (options: ToastSendOptions) => string;
  clear: () => void;
  removeItem: (id: string) => void;
  mutateItem: (id: string, action: MutateAction<ToastMessage>) => void;
}
