import { FCC } from 'fcc';
import { CSSProperties } from 'react';

const style: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '100%'
};
export const PageLayoutContent: FCC = ({ children }) => (
  <div style={style} data-name="page-layout-content">
    {children}
  </div>
);

type OptionalPageLayoutContentProps = {
  hide?: boolean;
};
export const OptionalPageLayoutContent: FCC<OptionalPageLayoutContentProps> = ({
  hide,
  children
}) => {
  if (hide) {
    return <>{children}</>;
  }

  return <PageLayoutContent>{children}</PageLayoutContent>;
};
