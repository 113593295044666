import {
  BoxProps as MantineBoxProps,
  ElementProps as MantineElementProps,
  getSize as getMantineSize,
  rem as mantineRem
} from '@mantine/core';
import cx from 'clsx';
import { ElementType } from 'react';

import { UiSize } from '../../theme/types';

export interface IHasIconSize {
  size?: UiSize | string | number;
}

export interface IHasIconColors {
  color?: string;
  color2?: string;
}

export interface IHasIconSizeAndColors extends IHasIconSize, IHasIconColors {}

export interface IconProps
  extends IHasIconSize,
    IHasIconColors,
    Omit<MantineBoxProps, 'h' | 'w'>,
    MantineElementProps<'span', 'onClick' | 'size' | 'type'> {
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  component?: ElementType;
  href?: string;
  flipped?: boolean;
  target?: string;
  mixBlendDifference?: boolean;
  svgProps?: Omit<
    React.SVGProps<SVGSVGElement>,
    'size' | 'height' | 'width' | 'viewBox'
  >;
}

export interface ColorIconProps extends Omit<IconProps, 'color' | 'color2'> {}

export const tablerIconClasses = (className?: string) =>
  cx(className, 'icon-tabler');

const sizes = {
  xs: mantineRem(8),
  sm: mantineRem(14),
  md: mantineRem(16),
  lg: mantineRem(24),
  xl: mantineRem(32)
};

export const getIconSize = (size: UiSize | string | number) => {
  if (size in sizes) {
    return sizes[size as any];
  }

  if (size === 'inherit') {
    return size;
  }

  return getMantineSize(size);
};
