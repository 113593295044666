'use client';
import { MantineTheme, parseThemeColor } from '@mantine/core';
import { CSSProperties, forwardRef } from 'react';

import { ThemeColors } from '../../theme/types';
import { useTheme } from '../../theme/useTheme';
import classes from './Spinner.module.css';

export interface SpinnerProps {
  size?: string | number;
  colorVariant?: keyof ThemeColors;
  color?: string;
  mixBlendDifference?: boolean;
  style?: CSSProperties;
}

const resolveColor = (
  theme: MantineTheme,
  colorVariant?: keyof ThemeColors,
  color?: string
) => {
  if (colorVariant)
    return `var(${parseThemeColor({ color: colorVariant, theme }).variable})`;
  if (color) return color;
  return 'currentColor';
};

export const Spinner = forwardRef<HTMLSpanElement, SpinnerProps>(
  ({ size, color, colorVariant, mixBlendDifference, style }, ref) => {
    const theme = useTheme();
    return (
      <span
        ref={ref}
        data-mix-blend-difference={mixBlendDifference}
        style={{
          width: size ?? '1.5rem',
          height: size ?? '1.5rem',
          borderColor: resolveColor(theme, colorVariant, color),
          borderBottomColor: 'transparent',
          borderLeftColor: 'transparent',
          ...style
        }}
        className={classes.root}
      />
    );
  }
);
