import lodashIsArray from 'lodash/isArray';

import { mapArray as mapArrayInternal } from '../_internal/mapArray';

export const isArray = lodashIsArray;

export const mapArray = mapArrayInternal;
/**
 * Split an array into many buckets of a given size.
 * @param items The items to bucket.
 * @param size The size of the bucket.
 */
export const bucketArray = <T>(items: T[], size: number): T[][] =>
  [...Array(Math.ceil(items.length / size))].map((_, i) =>
    items.slice(size * i, size + size * i)
  );

export const last = <T>(items: T[]): T | undefined => {
  return items[items.length - 1];
};

/**
 * Returns new array.
// Info: https://medium.com/dailyjs/how-to-remove-array-duplicates-in-es6-5daa8789641c
 * @param array The array values.
 */
export function removeDuplicates<T>(array: T[]) {
  if (!array || !array.length) return [] as T[];
  const unique = new Set(array);
  return [...(unique as any)] as T[];
}

export function removeDuplicatesMultiple<T>(array1: T[], array2: T[]) {
  let joined = !array1 ? [] : [...array1];
  if (array2 && array2.length) joined = joined.concat([...array2]);
  return removeDuplicates<T>(joined);
}

/**
 * Try to add new item to array.
 * @param array The array values.
 * @param item The item you want to add.
 * @param equalityPredicate how to determine item equality.
 */
export function tryAddToArray<T>(
  array: T[],
  item: T,
  equalityPredicate: (itemInArray: T) => boolean
) {
  if (!array || item === undefined || item === null) return;
  if (!array.some((e) => equalityPredicate(e))) {
    array.push(item);
  }
}

/**
 * Add item at index to array and reorder the array.
 * @param array The array values.
 * @param item The item you want to add.
 * @param index The index where you want to add the item.
 */
export function addToArrayAndReorder<TItem extends IOrdered>(
  array: TItem[],
  item: TItem,
  index: number
) {
  array.splice(index, 0, item);
  updateOrderProp(array);
}

/**
 * Try to add or update item to/in array.
 * @param array The array values.
 * @param item The item you want to add.
 * @param equalityPredicate how to determine item equality.
 */
export function addOrReplaceItemToArray<T>(
  array: T[],
  item: T,
  equalityPredicate: (item: T) => boolean
) {
  if (!array || item === undefined || item === null) return;
  const index = array.findIndex((e) => equalityPredicate(e));
  if (index >= 0) {
    array[index] = item;
  } else {
    array.push(item);
  }
}

/**
 * Try to update item to/in array.
 * @param array The array values.
 * @param item The item you want to add.
 * @param equalityPredicate how to determine item equality.
 */
export function tryUpdateItemInArray<T>(
  array: T[],
  equalityPredicate: (itemInArray: T) => boolean,
  updateFunc: (item: T) => T
) {
  if (!array) return;
  const index = array.findIndex((e) => equalityPredicate(e));
  if (index >= 0) {
    array[index] = updateFunc(array[index]);
  }
}

/**
 * Try to update item to/in array.
 * @param array The array values.
 * @param item The item you want to add.
 * @param equalityPredicate how to determine item equality.
 */
export function tryReplaceItemInArray<T>(
  array: T[],
  equalityPredicate: (itemInArray: T) => boolean,
  item: T
) {
  if (!array) return false;
  const index = array.findIndex((e) => equalityPredicate(e));
  if (index >= 0) {
    array[index] = item;
    return true;
  }
  return false;
}

/**
 * Try remove item from array
 * @param array The array values.
 * @param equalityPredicate how to determine item equality.
 */
export function tryRemoveFromArray<TItem>(
  array: TItem[],
  equalityPredicate: (item: TItem) => boolean
) {
  if (!array) return false;
  const index = array.findIndex((e) => equalityPredicate(e));
  if (index < 0) return false;
  array.splice(index, 1);
  return true;
}

/**
 * Create an array containing a series of numbers starting at the given number
 * @param start The number to start with
 * @param count The number of entries
 */
export const createFromRange = (start: number, count: number) => {
  return Array.from(new Array(count), (x, i) => i + start);
};

/**
 Moves the item to the new position in the input array. Useful for huge arrays where absolute performance is needed.

 @param array - The array to modify.
 @param fromIndex - The index of item to move. If negative, it will begin that many elements from the end.
 @param toIndex - The index of where to move the item. If negative, it will begin that many elements from the end.

 @example
 ```
 import {arrayMoveMutable} from 'array-move';

 const input = ['a', 'b', 'c'];

 arrayMoveMutable(input, 1, 2);

 //=> ['a', 'c', 'b']
 ```
 */
export function arrayMoveMutable(
  array: unknown[],
  fromIndex: number,
  toIndex: number
): void {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

/**
 Clones the given `array`, moves the item to a new position in the new array, and then returns the new array. The given `array` is not mutated.

 @param array - The array with the item to move.
 @param fromIndex - The index of item to move. If negative, it will begin that many elements from the end.
 @param toIndex - The index of where to move the item. If negative, it will begin that many elements from the end.
 @returns A new array with the item moved to the new position.

 @example
 ```
 import {arrayMoveImmutable} from 'array-move';

 const input = ['a', 'b', 'c'];

 const array1 = arrayMoveImmutable(input, 1, 2);
 //=> ['a', 'c', 'b']

 const array2 = arrayMoveImmutable(input, -1, 0);
 //=> ['c', 'a', 'b']

 const array3 = arrayMoveImmutable(input, -2, -3);
 //=> ['b', 'a', 'c']
 ```
 */
export function arrayMoveImmutable<ValueType>(
  array: readonly ValueType[],
  fromIndex: number,
  toIndex: number
): ValueType[] {
  const copy = [...array];
  arrayMoveMutable(copy, fromIndex, toIndex);
  return copy;
}

interface IOrdered {
  order: number;
}

export const updateOrderProp = <T extends IOrdered>(array: T[]): T[] => {
  return array.map((element, index) => {
    element.order = index + 1;
    return element;
  });
};

/**
 Clones the given `array`, moves the item to a new position in the new array, and then updates the order property on each object in the array. returns the new array.

 @param array - The array with the item to move.
 @param fromIndex - The index of item to move. If negative, it will begin that many elements from the end.
 @param toIndex - The index of where to move the item. If negative, it will begin that many elements from the end.
 @returns A new array with the item moved to the new position.

 */
export const arrayMoveAndReorder = <T extends IOrdered>(
  array: T[],
  fromIndex: number,
  toIndex: number
) => {
  return updateOrderProp(arrayMoveImmutable(array, fromIndex, toIndex));
};

/**
 * Try to remove item from given array and then update the order property on each object in the array.
 * The array is updated in place.
 * @param array The array values.
 * @param equalityPredicate how to determine item equality.
 */
export const tryRemoveAndReorder = <TItem extends IOrdered>(
  array: TItem[],
  equalityPredicate: (item: TItem) => boolean
) => {
  if (tryRemoveFromArray(array, equalityPredicate)) {
    updateOrderProp(array);
    return true;
  }
  return false;
};

export const arrayMax = <T = unknown>(
  array: T[],
  selector: (x: T) => number,
  defaultValue?: number
) => {
  if (array.length === 0) {
    return defaultValue;
  }

  return Math.max.apply(null, array.map(selector));
};

export const arrayMin = <T = unknown>(
  array: T[],
  selector: (x: T) => number,
  defaultValue?: number
) => {
  if (array.length === 0) {
    return defaultValue;
  }

  return Math.min.apply(null, array.map(selector));
};

export const isObjectEmptyOrUndefined = (x) =>
  Object.values(x || {}).every((el) => el === undefined);

/**
 * Returns the object in a single-item array if the object has any defined properties.
 *
 * If the object is undefined or empty (i.e. all properties undefined), this will return an empty array.
 *
 * Useful for conditionally including an object in an array, by spreading the return of this method.
 * @param x
 */
export const arrayItemIfObjectIsNotEmpty = (x) =>
  isObjectEmptyOrUndefined(x) ? [] : [x];

/**
 * Return the object as an array item if the condition passes, otherwise return an empty array.
 * Useful for conditionally spreading objects into an array.
 * @param condition The condition to check.
 * @param x The object to wrap in an array if the condition passes.
 */
export const arrayItemIf = <T = unknown>(condition: boolean, x: T) =>
  condition ? [x] : [];

export const arrayItemIfDefined = <T = unknown>(x: T) => arrayItemIf(!!x, x);

export function shuffleArray<T = unknown>(array: T[]) {
  const newArray = [...array];

  let currentIndex = newArray.length;
  let temporaryValue: any;
  let randomIndex: number;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = newArray[currentIndex];
    newArray[currentIndex] = newArray[randomIndex];
    newArray[randomIndex] = temporaryValue;
  }

  return newArray;
}
