import Head from 'next/head';
import { FC } from 'react';

interface Props {
  title?: string;
}

export const PageLayoutMeta: FC<Props> = ({ title }) => {
  return (
    <>
      <Head key="page-layout-meta">
        <title>{title || 'Loading...'}</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta httpEquiv="Accept-CH" content="DPR, Viewport-Width, Width" />
      </Head>
    </>
  );
};
