'use client';
import {
  Box as MantineBox,
  BoxProps as MantineBoxProps,
  PolymorphicFactory as MantinePolymorphicFactory,
  polymorphicFactory as mantinePolymorphicFactory
} from '@mantine/core';
import cx from 'clsx';
import { CSSProperties } from 'react';

interface LegacyBoxStyles {
  /**
   * @deprecated use maw
   */
  maxWidth?: MantineBoxProps['maw'];

  /**
   * @deprecated use m
   */
  margin?: MantineBoxProps['m'];

  /**
   * @deprecated use p
   */
  padding?: MantineBoxProps['p'];

  /**
   * @deprecated use css module
   */
  overflow?: CSSProperties['overflow'];
}

export interface BoxProps extends MantineBoxProps, LegacyBoxStyles {}

type BoxFactory = MantinePolymorphicFactory<{
  props: BoxProps;
  defaultComponent: 'div';
  defaultRef: HTMLDivElement;
}>;

type BoxReturnType = ReturnType<typeof mantinePolymorphicFactory<BoxFactory>>;
/**
 * @see https://mantine.dev/core/box/
 */
export const Box = mantinePolymorphicFactory<BoxFactory>(
  (
    {
      overflow,
      margin,
      padding,
      maxWidth,
      className,
      __vars: __varsProp = {},
      ...rest
    },
    ref
  ) => {
    const __vars = __varsProp || {};
    __vars['--box-overflow'] = overflow ?? 'initial';

    return (
      <MantineBox
        m={margin}
        p={padding}
        maw={maxWidth}
        className={cx('mantine-Box', className)}
        __vars={__vars}
        {...rest}
        ref={ref}
      />
    );
  }
) as BoxReturnType;
