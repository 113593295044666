import { urlAlphabet } from './urlAlphabet';

export const nanoid = (size: number = 21, customAlphabet?: string) => {
  const alphabet = customAlphabet || urlAlphabet;
  const alphabetLength = alphabet.length;
  let id = '';
  // A compact alternative for `for (var i = 0; i < step; i++)`.
  let i = size;
  while (i--) {
    // `| 0` is more compact and faster than `Math.floor()`.
    id += alphabet[(Math.random() * alphabetLength) | 0];
  }
  return id;
};
