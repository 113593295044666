import { NextPage } from 'next';
import React from 'react';

import { PageLayout } from '@/common/layouts';
import { SiteStatuses } from '@/common/models/site/SiteStatuses';
import { tryGetSiteFromHostAsync } from '@/common/utils/SiteHostSeverSideFunctions';
import { SiteErrored } from '@/front/components/site/SiteHost/SiteErrored';

const NoSite: NextPage = () => {
  return (
    <PageLayout title={'Hub not found'}>
      <SiteErrored errorCode="404" errorMessage="Site not found" />
    </PageLayout>
  );
};

export default NoSite;

export const getServerSideProps = async (context) => {
  // Try to get the site again, just incase its there now
  const site = await tryGetSiteFromHostAsync(context);
  if (site && site.status === SiteStatuses.Published) {
    return {
      redirect: {
        destination: '/',
        permanent: false
      }
    };
  }

  return { props: {} };
};
