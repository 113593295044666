import { MutateAction } from '@komo-tech/core/models/Updates';
import { addOrReplaceItemToArray } from '@komo-tech/core/utils/array';
import { nanoid } from '@komo-tech/core/utils/nanoid';
import { ReactNode, useState } from 'react';

import { FCC } from '../../fcc';
import { useEvent } from '../../hooks/useEvent';
import { ToastConfig } from './ToastConfig';
import { ToastContainer } from './ToastContainer';
import { ToasterContext } from './ToasterContext';
import { ToastMessage } from './ToastMessage';
import { ToastPosition } from './ToastPosition';
import { ToastSendOptions } from './ToastSendOptions';
import { ToastTypes } from './ToastTypes';

interface Props {
  position?: ToastPosition;
}

export const ToasterProvider: FCC<Props> = ({ position, children }) => {
  const [list, setList] = useState<ToastMessage[]>([]);

  const clear = useEvent(() => {
    setList([]);
  });

  const removeItem = useEvent((id: string) => {
    setList((state) => state.filter((x) => x.id !== id));
  });

  const mutateItem = useEvent(
    (id: string, action: MutateAction<ToastMessage>) => {
      setList((state) => {
        const updated = [...state];
        const index = updated.findIndex((x) => x.id === id);
        if (index >= 0) {
          action(updated[index]);
        }

        return updated;
      });
    }
  );

  const pop = useEvent(
    ({
      type = ToastTypes.Default,
      message,
      title,
      config: configProp,
      icon,
      noIconBg,
      ...rest
    }: ToastSendOptions) => {
      const config = Object.assign(
        {
          autoHide: true,
          autoHideTime: 4_000,
          id: nanoid(),
          actionIcon: 'none'
        },
        configProp
      );

      const msg: ToastMessage = {
        id: config.id,
        type,
        message: trySanitiseMessage(message),
        config,
        title,
        noIconBg,
        icon,
        ...rest
      };
      setList((state) => {
        const copy = [...state];
        addOrReplaceItemToArray(copy, msg, (x) => x.id === msg.id);
        return copy;
      });

      if (config.autoHide) {
        const timer = setTimeout(() => {
          removeItem(msg.id);
          clearTimeout(timer);
        }, config.autoHideTime);
      }

      return msg.id;
    }
  );

  const message = useEvent(
    (message: string | ReactNode, config?: ToastConfig) =>
      pop({
        type: ToastTypes.Default,
        message,
        config
      })
  );

  const info = useEvent((message: string | ReactNode, config?: ToastConfig) =>
    pop({
      type: ToastTypes.Info,
      message,
      config
    })
  );
  const error = useEvent((message: string | ReactNode, config?: ToastConfig) =>
    pop({
      type: ToastTypes.Error,
      message,
      config
    })
  );
  const warning = useEvent(
    (message: string | ReactNode, config?: ToastConfig) =>
      pop({
        type: ToastTypes.Warning,
        message,
        config
      })
  );
  const success = useEvent(
    (message: string | ReactNode, config?: ToastConfig) =>
      pop({
        type: ToastTypes.Success,
        message,
        config
      })
  );
  const pill = useEvent((message: string | ReactNode, config?: ToastConfig) =>
    pop({
      type: ToastTypes.Pill,
      message,
      config
    })
  );

  const pushNotification = useEvent(
    ({
      type = ToastTypes.Default,
      message,
      title,
      icon,
      config,
      noIconBg,
      ...rest
    }: ToastSendOptions) =>
      pop({
        type,
        message,
        title,
        icon,
        config: { toastRadius: 'md', actionIcon: 'close', ...config },
        noIconBg,
        ...rest
      })
  );

  const [providerValue] = useState({
    message,
    info,
    mutateItem,
    removeItem,
    success,
    error,
    warning,
    clear,
    pill,
    pushNotification
  });

  return (
    <ToasterContext.Provider value={providerValue}>
      {children}
      <ToastContainer
        defaultPosition={position || ToastPosition.BottomLeft}
        messages={list}
        onRemove={removeItem}
      />
    </ToasterContext.Provider>
  );
};

export const OptionalToasterProvider: FCC<Props & { hide?: boolean }> = ({
  hide,
  position,
  children
}) => {
  return hide ? (
    <>{children}</>
  ) : (
    <ToasterProvider position={position}>{children}</ToasterProvider>
  );
};

const trySanitiseMessage = (message: string | ReactNode) => {
  try {
    if (!message || typeof message !== 'string') return message;
    const split = message.split('\r\n');
    if (split.length === 1) return message;

    return (
      <>
        {split.map((x, i) => (
          <>
            {i !== 0 && <br />}
            {x}
          </>
        ))}
      </>
    );
  } catch (e) {
    console.error(e);
    return message;
  }
};
